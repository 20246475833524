import React from "react";
import { Button, Row, Col, Card, Form, InputGroup } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faContactBook,
  faEnvelope,
  faUser,
  faPhone,
  faMessage,
} from "@fortawesome/free-solid-svg-icons";

function ContactForm() {
  const handleSubmit = (event) => {
    event.preventDefault();
  };
  return (
    <Row className="g-4 justify-content-center">
      <Col md={10}>
        <Card className="card text-center">
          <Card.Body>
            <FontAwesomeIcon icon={faContactBook} size="3x" />
            <Card.Title className="mt-3 cardTitle">Contact Us</Card.Title>
            <Card.Text>
              <form
                name="contact"
                method="POST"
                data-netlify="true"
                netlify-honeypot="bot-field"
                onSubmit={handleSubmit}
              >
                <input type="hidden" name="form-name" value="contact" />
                <div style={{ display: "none" }}>
                  <input name="bot-field" />
                </div>
                <Form.Group className="mb-3" controlId="formName">
                  <Form.Label>Your Name</Form.Label>
                  <InputGroup>
                    <InputGroup.Text>
                      <FontAwesomeIcon icon={faUser} />
                    </InputGroup.Text>
                    <Form.Control
                      type="text"
                      name="name"
                      placeholder="Enter your name"
                      required
                    />
                  </InputGroup>
                </Form.Group>

                <Form.Group className="mb-3" controlId="formEmail">
                  <Form.Label>Your Email</Form.Label>
                  <InputGroup>
                    <InputGroup.Text>
                      <FontAwesomeIcon icon={faEnvelope} />
                    </InputGroup.Text>
                    <Form.Control
                      type="email"
                      name="email"
                      placeholder="Enter your email"
                      required
                    />
                  </InputGroup>
                </Form.Group>

                <Form.Group className="mb-3" controlId="formPhone">
                  <Form.Label>Your Phone Number</Form.Label>
                  <InputGroup>
                    <InputGroup.Text>
                      <FontAwesomeIcon icon={faPhone} />
                    </InputGroup.Text>
                    <Form.Control
                      type="tel"
                      name="phone"
                      placeholder="Enter your phone number"
                      pattern="[0-9]{10,}"
                      required
                    />
                  </InputGroup>
                </Form.Group>

                <Form.Group className="mb-3" controlId="formMessage">
                  <Form.Label>Message</Form.Label>
                  <InputGroup>
                    <InputGroup.Text>
                      <FontAwesomeIcon icon={faMessage} />
                    </InputGroup.Text>
                    <Form.Control
                      as="textarea"
                      name="message"
                      rows={4}
                      placeholder="Write your message here"
                      required
                    />
                  </InputGroup>
                </Form.Group>

                <Button type="submit" variant="success">
                  Send Message
                </Button>
              </form>
            </Card.Text>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
}

export default ContactForm;
