import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import Header from "./components/header";
import Footer from "./components/footer";
import Home from "./screens/home";
import FileUpload from "./components/upload";
import ContactForm from "./components/contact";
import ThankYou from "./components/thankYou";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

function App() {
  return (
    <div className="App">
      <Router>
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/app" element={<FileUpload />} />
          <Route path="/contact" element={<ContactForm />} />
          <Route path="/thank-you" element={<ThankYou />} />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
