import React from "react";

function ThankYou() {
  return (
    <div>
      <h1>Thank You for Contacting Us!</h1>
      <p>
        Your message has been received, and we will get back to you shortly.
      </p>
    </div>
  );
}

export default ThankYou;
